:root {
  --main: #2c4caa;
  --second: #0e284b;
  --black: #000;
  --white: #fff;
}

/* container du login */
.login-container {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* logo */
#login-logo {
  width: 140px;
  height: auto;
  margin: 0 0 10px 0;
}
/* content du login */
.login-content {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url(../../public/assets/images/home-background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.title-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.title-content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.title-container h2 {
  text-align: center;
  color: white;
  font-family: "Poppins";
  font-size: 1.9rem;
  line-height: 34px;
  font-weight: 600;
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: center;
}
.login-content .underline {
  width: 61px;
  height: 6px;
  background-color: #2c4caa;
  border-radius: 9px;
}
/* form */
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

/* input-div form  */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;
  width: 80%;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}
.input-container label {
  display: none;
}
.input-container input {
  padding: 8px;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 15px;
}
.input-container svg {
  margin: 0 10px 0 10px;
  color: var(--main);
}

/* button submit */
.submit-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 5px 0 0 0;
}
.inputMail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}
.inputMail-container label {
  display: none;
}
.cgu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.centered {
  text-align: center;
  font-size: 13px;
  width: 50%;
  cursor: pointer;
  font-style: italic;
  color: white;
}
input[type="checkbox"] {
  width: 25px;
  height: 25px;
}
.inputMail-container input {
  padding: 8px;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 15px;
}
.inputMail-container svg {
  margin: 0 10px 0 10px;
  color: var(--main);
}
.submit-container button {
  margin-top: 10px;
  border-radius: 26px;
  background-color: var(--main);
  color: white;
  font-size: 0.95rem;
  border: none;
  padding: 15px;
  transition: ease-in-out 0.3s;
  width: 180px;
  height: 50px;
  cursor: pointer;
}
.submit-container .gray {
  margin: 5px;
  width: 150px;
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #e3e3e3 0,
    #727272 100%
  );
  border: 0;
  border-radius: 26px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 55px;
  justify-content: center;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}
.submit-container .home-button {
  box-shadow: rgba(11, 7, 19, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(43, 43, 77, 0.5) 0 -3px 0 inset;
  font-family: "Poppins", sans-serif;
  height:55px;
}


.error-message {
  background-color: red;
  border-radius: 5px;
  padding: 7px;
  margin: 10px 0 0 0;
  color: white;
  width: 78%;
}

.error-message p {
  font-size: 14px;
}
.success-message {
  background-color: green;
  border-radius: 5px;
  padding: 7px;
  margin: 10px 0 0 0;
  color: white;
  width: 78%;
}
.success-message p {
  font-size: 14px;
}

.condition {
  color: white;
  font-size: 12px;
  text-align: start;
  margin: 0 0 10px 0;
  width: 80%;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 50px 0;
}

.link-sendEmail {
  text-decoration: none;
  color: white;
}
.link-sendEmail:hover {
  color: var(--second);
  transition: color 0.5s ease-in-out;
}

.return-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;
  color: #333;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
}
.return-link {
  margin-right: 10px;
  color: var(--main);
  text-decoration: none;
}

.arrow {
  margin-right: 5px;
}
@media (max-width: 1200px) {
  .success-message {
    background-color: green;
    border-radius: 5px;
    padding: 10px;
    color: white;
    text-align: center;
  }

  .submit-container button {
    margin-top: 10px;
    border-radius: 26px;
    background-color: var(--main);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 15px;
    transition: ease-in-out 0.3s;
    width: 170px;
  }

  .submit-container .gray {
    width: 170px;
    margin-top: 10px;
    height: 55px;
    font-size: 1rem;
  }
}
