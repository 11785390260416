.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loading-dots {
  color: white;
  margin: 0 0 15px 0;
  font-size: 18px;
  font-family: "Comic", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.loading-bar {
  height: 10px;
  background-color: #fff;
  border-radius: 10px;
  animation: loadingAnimation 1.5s linear infinite;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-dots span {
  animation: dotAnim 3s linear infinite;
  animation-delay: 0.2s;
  display: inline-block;
  margin: 2px;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

.overlay-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
@keyframes loadingAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes dotAnim {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
