.home-container {
    position: relative;
    width: 100vw;
    height: 100dvh;
    background-image: url("/public/assets/images/home-background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    touch-action: pan-x pan-y;
}

.home-content {
    width: 70%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 15px 0 15px;
}

.redirect-start {
    width: 100%;
    text-align: center;
    margin: 10px;
}

.redirect-login {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.home-button {
    width: 85%;
    margin: 5px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(
            100% 100% at 100% 0,
            #5b9cf3 0,
            var(--main) 100%
    );
    border: 0;
    border-radius: 26px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    font-family: "Comic", sans-serif;
    list-style: none;
    overflow: hidden;
    padding: 30px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.home-button:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.home-button:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
}

.home-button:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
}

.logout {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.bird-animation {
    margin-top: 30px;
}

.bird-animation img {
    animation: animationBird 3s linear infinite;
}

.empty-battery {
    color: rgb(82, 82, 82);
    padding: 5px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.modalFirstCo-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    top: 47.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 90%;
    background-image: radial-gradient(
            100% 100% at 100% 0,
            var(--second) 0,
            var(--main) 100%
    );
    color: white;
    font-family: "Comic", sans-serif;
}

.headerFirstCo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 0 5px;
    letter-spacing: 2px;
    line-height: 22px;
    font-weight: normal;

}

.headerFirstCo-container h1 {
    font-size: 1.5rem;
    margin: 10px 0;
    text-align: center;
}

.headerFirstCo-container h3 {
    font-size: 0.9rem;
    text-align: center;
    margin: 0 10px;
}

.inputFirstCo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inputFirstCo-container input {
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
}

.inputFirstCo-container button {
    margin: 5px;
    background-image: radial-gradient(100% 100% at 100% 0, #5b9cf3 0, var(--main) 100%);
    border: 0;
    border-radius: 30px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    color: #fff;
    font-family: "Comic", sans-serif;
    padding: 20px;
    transition: box-shadow 0.15s, transform 0.15s;
    will-change: box-shadow, transform;
    font-size: 14px;
}

.modalFirstCo-container .accept-username {
    margin: 0 0 20px 0;
    background-image: radial-gradient(100% 100% at 100% 0, #5b9cf3 0, var(--main) 100%);
    border: 0;
    border-radius: 30px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    color: #fff;
    cursor: pointer;
    font-family: "Comic", sans-serif;
    padding: 20px;
    transition: box-shadow 0.15s, transform 0.15s;
    will-change: box-shadow, transform;
    font-size: 18px;
}

@keyframes animationBird {
    0% {
        transform: translateX(-100px);
    }
    25% {
        transform: translateY(-30px);
    }
    50% {
        transform: translateX(100px);
    }
    75% {
        transform: translateX(0) rotateY(180deg);
    }
    100% {
        transform: translateX(-100px) rotateY(180deg);
    }
}
