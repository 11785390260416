.modalcgu-container {
  position: fixed;
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    var(--second) 0,
    var(--main) 100%
  );
  color: white;
  height: 90%;
}

.modalcgu-content h2{
font-family: "Poppins";
margin: 5px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modalcgu-content {
  padding: 0 10px 10px 10px;
  height: 95%;
  overflow-y: auto;
}
.modalForm-text h6 {
  text-align: center;
  margin: 0 0 10px 0;
}

.modalForm-text{
  margin: 10px 0 0 0;
}

.modalForm-text p{
  text-align: start;
  margin: 0 0 10px 0;
}
.modalForm-text ul {
  margin:  0 0 10px 0;
}
.modalForm-text ul li {
  margin:  0 0 10px 0;
}