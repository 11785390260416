@font-face {
  font-family: "Comic";
  src: url("../../public/fonts/HVD_Comic_Serif_Pro.otf");
}

.modal-container {
  position: fixed;
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    var(--second) 0,
    var(--main) 100%
  );
  color: white;
  height: 500px;
  font-family: "Comic", sans-serif;
}
h1,
h2 {
  font-family: "Comic", sans-serif;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0 5px;
}
.header-container span {
  font-size: 30px;
  cursor: pointer;
}
.header-container h1 {
  font-size: 30px;
  margin: 0 0 0 7px;
  padding: 6px 0;
}

.modal-content {
  padding: 0 10px 10px 10px;
  height: 345px;
  overflow-y: auto;
}

.modal-content li {
  list-style: none;
  border-radius: 10px;
  margin: 12px 0;
  height: 50px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.first {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.score {
  background-color: black;
  border-radius: 0 10px 10px 0;
  width: 80px;
  height: 100%;
  text-align: end;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid black;
}
.first .score svg path {
  fill: #ffd700 !important;
}
.second .score svg path {
  fill: silver !important;
}
.third .score svg path {
  fill: rgb(197, 121, 51) !important;
}
.score svg path {
  fill: #6dd5ed !important;
}

.second {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #d5d5d5 0%,
      #d7d7d7 8%,
      #9e9e9e 30%,
      #8f8f8f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #c0c0c0 0%,
      #ffffff 8%,
      #dddddd 25%,
      #c0c0c0 62.5%,
      #c0c0c0 100%
    );
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.third {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fffaf7 0%,
      #a95629 8%,
      #993c0a 30%,
      #863103 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #e7986e 0%,
      #ff863b 8%,
      #ca7345 25%,
      #ca7345 62.5%,
      #ca7345 100%
    );
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otherEven {
  background: linear-gradient(to right, #2193b0, #6dd5ed);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otherOdd {
  background: linear-gradient(to right, #2193b09f, #6dd5ed90);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userPosition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 10px;
  font-size: 18px;
}
.userPosition span {
  font-family: "Comic", sans-serif;
  margin: 0 5px 0 0;
}

h2 {
  font-size: 18px;
}

.userRanking-container {
  padding: 9px 6px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px -8px 7px -3px rgba(0, 0, 0, 0.6);
  background-color: #212757;
}

.userRanking-container li {
  list-style: none;
  border-radius: 10px;
  margin: 12px 0 0 0;
  width: 100%;
  height: 50px;
}

.loader {
  border: 5px solid #f3f3f3;
  transform: translate(-45%, -35%);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  margin: 50px auto;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
