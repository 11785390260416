:root {
  --main: #2c4caa;
  --second: #0e284b;
  --black: #000;
  --white: #fff;
}
.App {
  width: 100vw;
  height: 100vh;
}
#logo {
  width: 80px;
  height: auto;
  padding: 5px;
  margin: 0 0 25px 0;
}

.block-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  background-color: #0b0209;
  touch-action: pan-x pan-y;
}
.board-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

canvas {
  background-image: url("../../public/assets/images/firstBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  border-radius: 10px;
}
.touch-screen {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  background-color: #000;
}
p {
  text-align: center;
}
.transition-third {
  transition: background-image 0.6s ease-in-out;
}
.transition-second {
  transition: background-image 0.6s ease-in-out;
}
.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 330px;
  height: 450px;
  background: radial-gradient(
    100% 100% at 100% 0,
    var(--second) 0,
    var(--main) 100%
  );
  border-radius: 10px;
}
.overlay-touchscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.overlay-touchscreen img {
  width: 30%;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  animation: zoomInOut 1.5s infinite alternate ease-in-out;
  cursor: pointer;
}

.button-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  
}
.reset-button,
.link-menu {
  width: 40%;
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #e33ae0 0,
    var(--main) 100%
  );
  border: 0;
  border-radius: 26px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  font-family: "Comic", sans-serif;
  list-style: none;
  overflow: hidden;
  padding: 30px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.reset-button:focus,
.link-menu:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e33ae0 0 -3px 0 inset;
}

.reset-button:hover,
.link-menu:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e33ae0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.reset-button:active .link-menu-active {
  box-shadow: #e33ae0 0 3px 7px inset;
  transform: translateY(2px);
}

.score-content {
  color: white;
  font-family: "Comic", sans-serif;
  text-align: center;
  padding: 5px;
  height: 200px;
  border: 2px solid #742073;
  border-radius: 5px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.638);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.score-content h2 {
  font-size: 30px;
  margin: 0 0 10px 0;
}
.score-content .birdGameOver {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
}
.score-content img {
  animation-name: birdGameOver;
  -webkit-animation-name: birdGameOver;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.score-content p {
  width: 60%;
  text-align: start;
}
.loader-gameboy {
  border: 5px solid #f3f3f3;
  transform: translate(-45%, -35%);
  border-top: 5px solid #e33ae0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.redirect-message {
  background-color: #f4f4f4;
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: shine 1s infinite alternate;
  text-align: center;
}

@keyframes birdGameOver {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateY(-20px);
  }
  75% {
    transform: translateY(-20px) rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

 @keyframes shine {
  0% {
    background-color: #f4f4f4; 
  }
  100% {
    background-color: #d6dee2; 
  }
} 
